body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:linear-gradient(0deg,rgba(38,188,126,0.8),rgba(38,188,126,0.8)),url(http://eprijournal.com/wp-content/uploads/2018/10/meadow-with-Wind-turbine-1024x777.jpg);
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
